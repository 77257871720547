import item1 from '../../assets/ssc/carousel/car_home.png'
import item2 from '../../assets/ssc/carousel/car_menu.png'
import item3 from '../../assets/ssc/carousel/car_values.png'
import item4 from '../../assets/ssc/carousel/car_search.png'
import item5 from '../../assets/ssc/carousel/car_details.png'
import item6 from '../../assets/ssc/carousel/car_empty.png'
import item7 from '../../assets/ssc/carousel/car_payment.png'
import item8 from '../../assets/ssc/carousel/car_checkout.png'

export const items = [
  {
    image: item1,
  },
  {
    image: item2,
  },
  {
    image: item3,
  },
  {
    image: item4,
  },
  {
    image: item5,
  },
  {
    image: item6,
  },
  {
    image: item7,
  },
  {
    image: item8,
  },
]

//
