import item2 from '../../assets/fh/carousel/fh_car_desktop1.png'
import item1 from '../../assets/fh/carousel/fh_car_desktop2.png'
import item3 from '../../assets/fh/carousel/fh_car_desktop3.png'
import item4 from '../../assets/fh/carousel/fh_car_desktop4.png'
export const items = [
  {
    image: item1,
  },
  {
    image: item2,
  },
  {
    image: item3,
  },
  {
    image: item4,
  },
]
